import * as React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

export const Head = () => (
  <>
    <title>Galeria naszych realizacji geodezyjnych | Pomiary i inwentaryzacje</title>
    <meta
      name="description"
      content="Zobacz naszą galerię zrealizowanych projektów geodezyjnych. Obejmuje ona pomiary geodezyjne, inwentaryzacje powykonawcze, nadzory geodezyjne oraz specjalistyczne pomiary dla klientów z sektora lotniskowego."
    />
    <link rel="canonical" href="https://geo-partner.pl/galeria/" />
  </>
);

const Main = styled.main`
  /* font-family: ${({ theme }) => theme.font.family.sans}; */

  p {
    line-height: 150%;
  }

  .subtitle {
    margin: 0;
    text-transform: uppercase;
  }
`;

const Slider = styled.div`
  .gatsby-image-wrapper-constrained {
    width: 100%;
  }
`;

const Gallery = styled.ul`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  list-style: none;
  padding: 0;

  img {
    height: auto;
  }

  @media only screen and (min-width: 640px) {
    grid-template-columns: repeat(3, 1fr);
  }
  
  @media only screen and (min-width: 960px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const GalleryItem = ({ image, alt }) => (
  <li>
    <Zoom>
      <img src={image.publicURL} alt={alt} width="263" height="197" />
    </Zoom>
  </li>
);

const AboutOffice = ({ data }) => (
  <>
    <Slider>
      <GatsbyImage
        image={getImage(data.hero_8)}
        alt="Geodezja Olsztyn"
        loading="eager"
      />
    </Slider>

    <Main>
      <div className="row">
        <h1>Galeria</h1>

        <Gallery>
          {Object.keys(data)
            .filter((key) => key.startsWith('gallery_'))
            .map((key) => (
              <GalleryItem key={key} image={data[key]} alt={key.replace('gallery_', 'zdjęcie ')} />
            ))}
        </Gallery>
      </div>
    </Main>
  </>
);

export const query = graphql`
  query {
    hero_8: file(relativePath: {regex: "/hero\/8.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 2000
        )
      }
    },
    gallery_1: file(relativePath: {regex: "/gallery\/big\/1.jpeg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 320
        )
      }
      publicURL
    }
    gallery_2: file(relativePath: {regex: "/gallery\/big\/2.jpeg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 320
        )
      }
      publicURL
    }
    gallery_3: file(relativePath: {regex: "/gallery\/big\/3.jpeg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 320
        )
      }
      publicURL
    }
    gallery_4: file(relativePath: {regex: "/gallery\/big\/4.jpeg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 320
        )
      }
      publicURL
    }
    gallery_5: file(relativePath: {regex: "/gallery\/big\/5.jpeg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 320
        )
      }
      publicURL
    }
    gallery_6: file(relativePath: {regex: "/gallery\/big\/6.jpeg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 320
        )
      }
      publicURL
    }
    gallery_7: file(relativePath: {regex: "/gallery\/big\/7.jpeg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 320
        )
      }
      publicURL
    }
    gallery_8: file(relativePath: {regex: "/gallery\/big\/8.jpeg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 320
        )
      }
      publicURL
    }
    gallery_11: file(relativePath: {regex: "/gallery\/big\/11.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 320
        )
      }
      publicURL
    }
    gallery_12: file(relativePath: {regex: "/gallery\/big\/12.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 320
        )
      }
      publicURL
    }
    gallery_13: file(relativePath: {regex: "/gallery\/big\/13.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 320
        )
      }
      publicURL
    }
    gallery_14: file(relativePath: {regex: "/gallery\/big\/14.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 320
        )
      }
      publicURL
    }
    gallery_15: file(relativePath: {regex: "/gallery\/big\/15.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 320
        )
      }
      publicURL
    }
    gallery_16: file(relativePath: {regex: "/gallery\/big\/16.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 320
        )
      }
      publicURL
    }
    gallery_17: file(relativePath: {regex: "/gallery\/big\/17.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 320
        )
      }
      publicURL
    }
    gallery_18: file(relativePath: {regex: "/gallery\/big\/18.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 320
        )
      }
      publicURL
    }
    gallery_19: file(relativePath: {regex: "/gallery\/big\/19.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 320
        )
      }
      publicURL
    }
  }
`;

export default AboutOffice;
